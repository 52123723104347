<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ template.id ? 'Edit' : 'New' }} mail template<br>
                <div class="mt-1">
                  <div class="form-check form-check-success form-switch d-flex align-items-center">
                    <input
                      id="publishContent"
                      v-model="template.active"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="publishContent"
                    >
                      <small class="ms-1">Active</small>
                    </label>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to areas & groups list"
              @click="$router.push({name: 'admin.mail-templates.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div
          v-if="errors != ''"
          class="alert alert-danger mt-1 p-1"
        >
          {{ errors }}
        </div>
        <div class="row">
          <form
            id="edit-form"
            action=""
          >
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h3 class="cart-title">
                    <a data-action="collapse">Template information</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content collapse show">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-8">
                        <div class="mb-1 form-group required">
                          <label
                            for=""
                            class="form-label"
                          >Title</label>
                          <input
                            v-model="template.title"
                            class="form-control"
                            :disabled="template.title === 'Evaluator credentials' || template.title === 'Reset password'"
                            type="text"
                          >
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="mb-1 form-group required">
                          <label
                            for=""
                            class="form-label"
                          >Description</label>
                          <quill-editor v-model="template.description" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h3 class="cart-title">
                    <a data-action="collapse">Content</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content collapse show">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <div class="alert alert-secondary">
                          <div class="alert-body">
                            <p><strong class="text-dark">[researcher]</strong> Displays the recipient's name</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Subject</label>
                          <input
                            v-model="template.content_subject"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Title</label>
                          <input
                            v-model="template.content_title"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-sm-12">
                        <ul
                          id="basic-list-group"
                          class="list-group"
                        >
                          <draggable
                            v-model="content"
                            group="favor"
                            @start="drag=true"
                            @end="drag=false"
                            @change="saveNewOrder"
                          >
                            <li
                              v-for="templateContent, index in content"
                              :key="templateContent.order"
                              class="list-group-item draggable"
                            >
                              <div class="row">
                                <div
                                  v-if="templateContent.type == 'text-block'"
                                  class="col-sm-10"
                                >
                                  <div class="mb-1">
                                    <label
                                      for=""
                                      class="form-label"
                                    >Block {{ index }}</label>
                                    <quill-editor
                                      v-model="templateContent.content"
                                      class="custom-quill"
                                    />
                                  </div>
                                </div>
                                <template v-if="templateContent.type == 'button'">
                                  <div class="col-sm-3">
                                    <div class="mb-1">
                                      <label
                                        for=""
                                        class="form-label"
                                      >Text button</label>
                                      <input
                                        v-model="templateContent.button_text"
                                        type="text"
                                        class="form-control"
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-5">
                                    <div class="mb-1">
                                      <label
                                        for=""
                                        class="form-label"
                                      >Link button</label>
                                      <input
                                        v-model="templateContent.button_link"
                                        type="text"
                                        class="form-control"
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-2">
                                    <div class="mb-1">
                                      <label
                                        for=""
                                        class="form-label"
                                      >Color</label>
                                      <select
                                        v-model="templateContent.button_color"
                                        name=""
                                        class="form-select"
                                      >
                                        <option value="primary">
                                          Icrea color
                                        </option> <!-- primary -->
                                        <option value="success">
                                          Green
                                        </option> <!-- success -->
                                        <option value="danger">
                                          Red
                                        </option> <!-- danger -->
                                        <option value="dark">
                                          Black
                                        </option> <!-- dark -->
                                      </select>
                                    </div>
                                  </div>
                                </template>
                                <div
                                  v-if="templateContent.type == 'image'"
                                  class="col-sm-10"
                                >
                                  <div class="mb-1">
                                    <label
                                      for=""
                                      class="form-label"
                                    >Image</label>
                                    <input
                                      id="formImage"
                                      :ref="`image-${index}`"
                                      class="form-control"
                                      type="file"
                                      @change="uploadImage(index)"
                                    >
                                    <img
                                      v-if="templateContent.image"
                                      :src="templateContent.image.url"
                                      class="img-fluid mt-1"
                                      style="max-height: 200px;"
                                      alt=""
                                    >
                                  </div>
                                  <img
                                    class="img-fluid"
                                    alt=""
                                  >
                                </div>
                                <div
                                  v-if="templateContent.type == 'file'"
                                  class="col-sm-10"
                                >
                                  <div class="mb-1">
                                    <label
                                      for=""
                                      class="form-label"
                                    >File</label>
                                    <input
                                      :ref="`file-${index}`"
                                      type="file"
                                      class="form-control"
                                      @change="uploadFile(index)"
                                    >
                                  </div>
                                </div>
                                <div class="col-sm-2">
                                  <label
                                    for=""
                                    class="form-label"
                                  >Actions</label>
                                  <div class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block">
                                    <button
                                      type="button"
                                      class="btn btn-primary"
                                      @click="remove(index)"
                                    >
                                      <i
                                        class="me-50"
                                        data-feather="trash-2"
                                      /> Delete <span class="float-end" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </draggable>
                        </ul>
                      </div>
                    </div>
                    <hr>
                    <h4>Add content</h4>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="add blocs"
                    >
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="addBlock('text-block')"
                      >
                        Text block
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="addBlock('button')"
                      >
                        Button
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="addBlock('image')"
                      >
                        Image
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="addBlock('file')"
                      >
                        Attach file
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h3 class="cart-title">
                    <a data-action="collapse">Preview</a>
                  </h3>
                  <div class="heading-elements">
                    <ul class="list-inline mb-0">
                      <li>
                        <a data-action="collapse"><i data-feather="chevron-down" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="card-content collapse show">
                  <div class="card-body">
                    <div class="row">
                      <div
                        class="col-sm-12"
                        v-html="templateHtml"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  components: {
    Draggable,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      templateId: this.$route.params.id,
      content: [],
      sending: false,
      errors: '',
    }
  },
  computed: {
    ...mapGetters({
      template: 'mailTemplates/item',
      templateHtml: 'mailTemplates/basicTemplate',
    }),
    contentTemp() {
      return this.template.content
    },
    titleTemp() {
      return this.template.content_title
    },
  },
  watch: {
    contentTemp: {
      handler() {
        this.content = Object.keys(this.template.content).map(key => this.template.content[key])
        this.loadPreview()
      },
      deep: true,
    },
    titleTemp() {
      this.loadPreview()
    },
  },
  async mounted() {
    if (this.templateId) {
      await this.$store.dispatch('mailTemplates/fetchId', this.templateId)
    } else {
      await this.$store.dispatch('mailTemplates/cleanType')
    }

    await this.$store.dispatch('mailTemplates/fetchBasicTemplate')
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
  methods: {
    async save() {
      this.sending = true

      try {
        if (this.templateId) {
          await this.$store.dispatch('mailTemplates/update', { id: this.templateId, data: this.template })
        } else {
          await this.$store.dispatch('mailTemplates/create', this.template)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.template.id) {
        Vue.swal('The template has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'admin.mail-templates.index' })
        })
      }

      this.sending = false
    },
    addBlock(type) {
      this.template.content.push({
        type,
        content: '',
        image: {},
        file: {},
      })

      this.loadPreview()

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
    saveNewOrder() {
      this.template.content = this.content
    },
    remove(index) {
      this.template.content.splice(index, 1)
    },
    loadPreview() {
      let string = ''

      this.content.forEach(item => {
        if (item.type === 'text-block') {
          string = `${string} <p class="sm-leading-32" style="font-size: 18px; margin: 0 0 24px; --text-opacity: 1; color: #263238; color: rgba(38, 50, 56, var(--text-opacity));">
                ${item.content}
            </p>`
        } else if (item.type === 'button') {
          string = `${string} <span style="padding-top:1rem; padding-bottom: 2rem;">
                <a href="${item.button_link}" class="btn btn-${item.button_color}">${item.button_text ?? 'Text button'}</a>
            </span>`
        } else if (item.type === 'image') {
          string = `${string} <div style="padding-top:1rem; padding-bottom: 2rem;">
                <img src="${item.image ? item.image.url : ''}" class="img-fluid" alt="">
            </div>`
        }
      })

      const mailContent = document.getElementById('mailContent')
      if (mailContent) {
        mailContent.innerHTML = string
      }

      const mailTitle = document.getElementById('mailTitle')
      if (mailTitle) {
        mailTitle.innerHTML = this.template.content_title
      }
    },
    uploadImage(index) {
      const file = this.$refs[`image-${index}`][0].files[0]
      // Save image
      this.template.content[index].image = {
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        file,
        name: file.name,
        original_name: file.name,
        url: URL.createObjectURL(file),
        id: this.template.content[index].image.id ?? null,
      }

      this.loadPreview()
    },
    uploadFile(index) {
      const file = this.$refs[`file-${index}`][0].files[0]
      // Save file
      this.template.content[index].file = {
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        file,
        name: file.name,
        original_name: file.name,
        url: URL.createObjectURL(file),
        id: this.template.content[index].file.id ?? null,
      }
    },
  },

}
</script>
